const style = {
    box:{
        width:'100vw',
        height:'20px',
        marginTop:2,
        display: 'flex',
        flexWrap: 'nowrap',
        color:'#E5E4E2',
        alignItems: 'center',
        justifyContent: 'space-evenly',                            
        '@media (max-width: 600px)': {
            marginTop:0,
            marginRight:'5px',                        
            flexWrap: 'wrap'            
           }  
    },
    link:{
        display:'flex',
        alignItems:'center',      
        color:'#FFFFFF',
        textDecoration:'none',
        '@media (max-width: 600px)': {                        
            margin:'10px'           
           }   
    }
}

export default style;