import { Box, Typography} from "@mui/material";
import Container from "../Components/Container/ContainerStyles";
import AboutUsStyle from "../Components/ContentStyles/ContentStyles";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { forwardRef } from "react";

const strongPoints = [
    'Comunicação responsável;',
    'Marketing de Impacto;',
    'Compromisso com a sustentabilidade;',
    'Visão holística;'
]

const youCan = [   
'Construir uma marca autêntica e engajada com seus valores.',
'Alcançar o público certo com a mensagem certa.',
'Gerar resultados positivos para o seu negócio e para o planeta.',
]
function HomePage({ text }, ref) {
    return (
        <Container sx={{           
            '@media (min-width: 600px)': {
                height: '70vh',
              },     
             '@media (min-width:480px) and (max-width:599px)':{
                height:'115vh'
             },
             '@media (min-width:320px) and (max-width:479px)':{
                height:'155vh'
             }
            }}>
            <Box sx={AboutUsStyle.boxGray}>
                <Box sx={{
                    padding: '10px 10%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    '@media (max-width: 600px)': {                        
                        flexDirection: 'column'
                       }  
                }}>
                    <Box sx={{
                        ...AboutUsStyle.boxtypography,
                        background: "linear-gradient(180deg, #E5E4E2 5%, transparent 50%)",
                    }}>
                        <Typography sx={AboutUsStyle.title} ref={ref}>{text}</Typography>
                        <Typography sx={AboutUsStyle.typographyDark}>
                            Comunicação Responsável para um Futuro Sustentável
                        </Typography>
                        <Typography sx={AboutUsStyle.typographyItalic}>
                            Sua marca com propósito, impactando positivamente o mundo.
                        </Typography>
                        <Typography>
                            Somos uma agência de Marketing Digital que acredita no poder da comunicação para gerar mudanças positivas. Combinamos criatividade, estratégia e expertise para conectar marcas com seus públicos de forma autêntica e significativa, promovendo o crescimento responsável e a sustentabilidade.
                        </Typography>
                    </Box>
                    <Box sx={{
                        ...AboutUsStyle.boxtypography,
                        background: "linear-gradient(180deg, #E5E4E2 5%, transparent 50%)",
                    }}>
                        <Typography sx={AboutUsStyle.title}>Nossos Diferenciais</Typography>
                        {strongPoints.map((option) => (
                            <Typography key={option} sx={{ display: 'flex' }}>
                                <CheckCircleOutlineIcon sx={{ marginRight: '10px', marginBottom: '10px', color:'#B37000'}} />
                                {option}
                            </Typography>
                        ))
                        }
                        <Typography sx={AboutUsStyle.subtitle}>
                            Com a Bio 3.0, você pode:
                        </Typography>
                        {youCan.map((option)=>
                        <Typography key={option} sx={{ display: 'flex' }}>
                            <CheckCircleOutlineIcon sx={{ marginRight: '10px', marginBottom: '10px', color:'#B37000' }} />
                            {option}
                        </Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </Container>

    )
}


export default forwardRef(HomePage);