const footerStyle={
    boxFooter:{
        width:'100vw',
        height:'50px',
        backgroundColor:'#6E1FA1',
        padding:'20px',
        display:'flex',
        alignItems:'center'
    },
    typography:{
        color: '#FFFFFF'
    }
}

export default footerStyle;