import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { autoPlay } from 'react-swipeable-views-utils';
import Container from '../Container/ContainerStyles';
import imageOne from '../../Assets/image1.jpg';
import imageTwo from '../../Assets/image2.jpg';
import imageThree from '../../Assets/image3.jpg';
import Catchphrase from '../Catchphrase/Catchphrase';
import CatchphraseStyle from '../Catchphrase/style';
import ImageAssignments from '../ImageAssignments/ImageAssignments';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
    {
        label: 'Missão',
        imgPath: imageOne,
        catchphrase: <Typography component='div' sx={CatchphraseStyle.typography}>Conectar <Box fontWeight='700' display='inline'>pessoas, empresas</Box> e <Box fontWeight='700' display='inline'>ideias</Box> em prol da sustentabilidade</Typography>,
        assignments: {
            link: "https://www.freepik.com/free-photo/digital-laptop-working-global-business-concept_2861977.htm#fromView=search&page=1&position=8&uuid=588dfdc8-807f-457c-a416-c310ece8f452",
            text: "Image by rawpixel.com on Freepik"
        }
    },
    {
        label: 'Visão',
        imgPath: imageTwo,
        catchphrase: <Typography component='div' sx={CatchphraseStyle.typography}>Contribuir para que negócios verdes <Box fontWeight='700' display='inline'>liderem o mercado</Box></Typography>,
        assignments: {
            link: "https://www.freepik.com/free-photo/front-view-man-with-wooden-blocks_38687397.htm#fromView=search&page=1&position=50&uuid=5c9eefbc-31ba-4261-846b-640073e6b2da",
            text: "Image by freepik"
        }
    },
    {
        label: 'Valores',
        imgPath: imageThree,
        catchphrase: <Typography component='div' sx={CatchphraseStyle.typography}><Box fontWeight='700' display='inline'>Responsabilidade, propósito, respeito, transparência, ética, resiliência, autenticidade e comunidade</Box></Typography>,
        assignments: {
            link: "https://www.freepik.com/free-photo/sustainable-development-goals-still-life_38687439.htm#fromView=search&page=1&position=49&uuid=5c9eefbc-31ba-4261-846b-640073e6b2da",
            text: "Image by freepik"
        }
    }
];

function ImagesInitials() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);


    const handleStepChange = (step) => {
        setActiveStep(step);
    };



    return (
        <Container sx={{
            '@media (max-width: 600px)': {
                height: '50vh',
            }
        }}>
            <Box sx={{
                Width: '100vw',
                marginTop: '5px',
                flexGrow: 1,
                backgroundColor: '#000000',
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
                '@media (max-width: 600px)': {
                    height: '50vh',
                }
            }}>
                <Catchphrase text={images[activeStep].catchphrase} />
                <ImageAssignments link={images[activeStep].assignments.link} text={images[activeStep].assignments.text} />
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {images.map((step, index) => (
                        <div key={step.label}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <div>
                                    <Box
                                        component="img"
                                        sx={{
                                            height: '100vh',
                                            display: 'block',
                                            maxWidth: '100vw',
                                            overflow: 'hidden',
                                            width: '100%',
                                            opacity: '30%',
                                            '@media (max-width: 600px)': {
                                                height: '50vh',
                                            }
                                        }}
                                        src={step.imgPath}
                                        alt={step.label}
                                    />
                                </div>
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
            </Box>
        </Container >
    );
}

export default ImagesInitials;