import { Box, Typography } from "@mui/material";
import Container from "../Components/Container/ContainerStyles";
import styles from "../Components/ContentStyles/ContentStyles";
import ContactUsForm from "../Components/ContactUsForm/ContactUsForm";
import { forwardRef } from "react";
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SocialMedia from "../Components/SocialMedia/SocialMedia";

const options = [
    'Responsáveis: alinhadas com os valores éticos e sociais da sua marca.',
    'Eficazes: geram resultados reais e tangíveis para o seu negócio.',
    'Sustentáveis: minimiza o impacto ambiental e contribui para um futuro mais verde.'
]
function Contacts({ text }, ref) {
    return (
        <Container sx={{
            '@media (min-width: 600px)': {
                height: '100vh',
            },
            '@media (min-width:480px) and (max-width:599px)': {
                height: '160vh'
            },
            '@media (min-width:320px) and (max-width:479px)': {
                height: '160vh'
            }
        }}>
            <Box sx={{
                ...styles.boxGreen,
                color: '#FCF7E3',
                '@media (min-width: 600px)': {
                    height: '100vh',
                },
                '@media (min-width:480px) and (max-width:599px)': {
                    height: '160vh'
                },
                '@media (min-width:320px) and (max-width:479px)': {
                    height: '160vh'
                }
            }}>
                <Box sx={{
                    padding: '10px 10%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    '@media (max-width: 600px)': {
                        flexDirection: 'column'
                    }
                }}>
                    <Box sx={styles.boxtypography}>
                        <Typography sx={{ ...styles.title, color: '#FCF7E3' }} ref={ref}> {text} </Typography>
                        <Typography sx={styles.typographyItalic}>
                            Somos a Bio 3.0, uma agência de Marketing Digital com a missão de:
                        </Typography>
                        <Typography sx={{ ...styles.typographyDark, display: 'flex' }}>
                            <NaturePeopleIcon sx={{ marginRight: '5px' }} />
                            Conectar pessoas, empresas e ideias para construir um futuro mais sustentável.
                        </Typography>
                        <Typography sx={styles.typography}>
                            Acreditamos que a comunicação pode ser uma ferramenta poderosa para o bem. Por isso, nos dedicamos a oferecer soluções de Marketing Digital e Branding que são:
                        </Typography>
                        {options.map((option) =>
                            <Typography key={option} sx={{ display: 'flex' }}>
                                <CheckCircleOutlineIcon sx={{ fontSize: '20px', marginRight: '5px', marginBottom: '10px' }} />
                                {option}
                            </Typography>
                        )}

                    </Box>
                    <Box sx={styles.boxtypography}>
                        <Typography sx={{ ...styles.title, color: '#FCF7E3' }}> Peça Seu Orçamento </Typography>
                        <ContactUsForm />
                    </Box>
                </Box>
                <SocialMedia />
            </Box>
        </Container>
    )
}


export default forwardRef(Contacts);